import React, { useEffect, useState } from 'react';
import { fetchIndeePlayer } from "../../utils/api/helpers/indee";
import mediaPlaceholder from '../../Common/_assets/img/placeholder/photo-placeholder.png'
import ScreenerListItem from "./ScreenerListItem";

const IndeePlayer = ({ screenerKey,
                         screenerList,
                         projectKey,
                         activeKey,
                         projectName,
                         activeScreener,
                         setActiveScreener,
                         viewList,
                         wmImage,
                         wmOpacity,
                         wmDuration,
                         wmPosition,
                         wmText,
                        wmInterval
}) => {

    const [ loaded, setLoaded ] = useState( false ),
        [ IndeePlayer, setIndeePlayer ] = useState( null ),
        [ indeeParams, setIndeeParams ] = useState( null );


    useEffect(() => {
        if(screenerKey) {
            // console.log(loaded, screenerKey, activeScreener)
            fetchIndeePlayer(screenerKey).then(result => {
                setLoaded(true);
                if (result.data.error && result.data.message.unauth) {
                    //setDisplayPinModal(true);
                    console.log("UNAUTH(Player)")
                } else {
                    // console.log('player return',result.data)
                    setIndeePlayer(result.data.embeddablePlayerHtml);
                    if (wmText !== '') {
                        let tempParams = result.data.params;
                        const overlayParams = {
                            "text": wmText,
                            "opacity": wmOpacity,
                            "position": wmPosition,
                            "interval_in_seconds": wmInterval,
                            "duration_in_seconds": wmDuration,
                            "image_link":wmImage
                        }
                        tempParams[1].overlayWatermarkDetails = overlayParams;
                        setIndeeParams(tempParams);
                    } else {
                        setIndeeParams(result.data.params);
                    }

                }
            });
        }
    }, [ screenerKey, wmPosition, wmOpacity, wmText, wmInterval, wmDuration, wmImage ]);

    // useEffect(() => {
    //     console.log(
    //         'wmImage: '+wmImage,'wmOpacity:'+wmOpacity,'wmDuration: '+wmDuration,'wmPosition: '+wmPosition,'wmText: '+wmText
    //     )
    // }, [ wmImage,wmOpacity,wmDuration,wmPosition,wmText ]);

    const loadScript = (url) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = url;
            script.onload = resolve;
            script.onerror = reject;
            document.body.appendChild(script);
        });
    };

    useEffect(() => {
        const url = 'https://api.indee.tv/v2/watch/stream/player/init';
        loadScript(url)
            .then(() => {
                if(window?.initializeIndeePlayer && indeeParams) {
                    console.log('indeeParams',indeeParams)
                    window.initializeIndeePlayer(...indeeParams);
                }
            }).catch(error => console.log("INDEE ERROR", error));
    }, [ indeeParams, IndeePlayer ]);



    return (
        <div className={'video-nav-outer-container d-flex'}>
            <div className={'col-3 flex-fill pe-2 '+(viewList ? 'd-block' : 'd-none')}>
                {
                    screenerList && screenerList.length > 0 ?
                        (
                            <div className={'scroll-container'} style={{
                                maxHeight: '80vh',
                                overflowY: 'scroll',
                                width: '100%',
                                transform: 'none'
                            }}>
                                {
                                    screenerList.map((scr, i) => {
                                        return <ScreenerListItem
                                            key={ `screener_${ projectKey }_${ i }` }
                                            projectName={projectName}
                                            scr={scr}
                                            setLoaded={setLoaded}
                                            activeScreener={activeScreener}
                                            setActiveScreener={setActiveScreener}
                                            projectKey={projectKey}
                                            activeKey={activeKey}
                                        />
                                    })
                                }
                            </div>
                        ) : (
                            <p>No Screeners Available...</p>
                        )
                }
            </div>
            <div className={'video-container'}>
                {/*{*/}
                {/*    wmImage ?*/}
                {/*        <div className={'d-none watermark-container'} style={{*/}
                {/*            top:wmPosition.toString()==='5'?'25%':wmPosition.toString()==='11'?'75%':'50%'*/}
                {/*        }}>*/}
                {/*            <img src={wmImage} alt={wmText} className={'watermark-image'} style={{*/}
                {/*                opacity:wmOpacity/100*/}
                {/*            }}/>*/}
                {/*        </div>*/}
                {/*        : null*/}
                {/*}*/}

                <img src={mediaPlaceholder} alt={"placeholder"} className="video-sizer"/>
                <div className={'video-outer-wrapper'}>
                    <div className={'video-inner-wrapper'}>
                        {
                            loaded ?
                                (
                                    <iframe title={`${screenerKey}`} id='video_player'
                                            srcDoc={IndeePlayer ? IndeePlayer : <></>}></iframe>
                                ) : <p>Loading...</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default IndeePlayer;
