import { useEffect, useState } from 'react';
import {
    fetchProjectScreeners
} from "../../utils/api/helpers/indee";

import ScreenerGridItem from "./ScreenerGridItem";
import ScreenerModal from "./ScreenerModal";

const ScreenerGrid = ({ projectKey, activeKey, projectName }) => {

    const [ loaded, setLoaded ] = useState( false ),
        [ screenerList, setScreenerList ] = useState( [] );

    const [ activeScreener, setActiveScreener ] = useState( "" ),
        [ openScreenerModal, setOpenScreenerModal ] = useState( false );

    const [ wmImage, setWmImage ] = useState( "" ),
        [ wmOpacity, setWmOpacity ] = useState( 1 ),
        [ wmDuration, setWmDuration ] = useState( 0 ),
        [ wmPosition, setWmPosition ] = useState( 0 ),
        [ wmText, setWmText ] = useState( "" ),
        [ wmInterval, setWmInterval ] = useState( 0 );

    useEffect(() => {
        if(projectKey === activeKey) {
            fetchProjectScreeners(projectKey)
                .then(result => {
                    setLoaded(true);
                    if(result.data.error && result.data.message.unauth)
                        console.log(result.data.error)
                    else {
                        setScreenerList(result.data.results);
                        setLoaded(true);
                    }
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ projectKey ]);

    return (
        <>
            {
                !loaded ?
                    <p>Loading...</p>
                    : (
                        <>
                            {
                                screenerList && screenerList.length > 0 ?
                                    (
                                        <div className={'row no-gutters'}>
                                            {
                                                screenerList.map((scr, i) => {
                                                    // if (scr.screening_details.screener_key) {
                                                        return <ScreenerGridItem
                                                            key={`screener_${projectKey}_${i}`}
                                                            projectKey={ projectKey }
                                                            projectName={projectName}
                                                            scr={scr}
                                                            setOpenScreenerModal={setOpenScreenerModal}
                                                            setActiveScreener={setActiveScreener}
                                                            setOverlayImage={setWmImage}
                                                            setOverlayOpacity={setWmOpacity}
                                                            setOverlayDuration={setWmDuration}
                                                            setOverlayPosition={setWmPosition}
                                                            setOverlayText={setWmText}
                                                            setOverlayInterval={setWmInterval}
                                                        />
                                                    // } else {
                                                    //     return null
                                                    // }
                                                })
                                            }
                                        </div>
                                    ) : (
                                        <p>No Screeners Available...</p>
                                    )
                            }
                        </>
                    )
            }

            <ScreenerModal projectKey={projectKey}
                           activeKey={activeKey}
                            projectName={projectName}
                           openScreenerModal={openScreenerModal}
                           setOpenScreenerModal={setOpenScreenerModal}
                           setActiveScreener={setActiveScreener}
                           activeScreener={activeScreener}
                           screenerList={screenerList}
                           wmImage={wmImage}
                           wmOpacity={wmOpacity}
                           wmDuration={wmDuration}
                           wmPosition={wmPosition}
                           wmText={wmText}
                           wmInterval={wmInterval}
            />

        </>
    )

}

export default ScreenerGrid;
