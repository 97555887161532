import React, {useEffect, useState} from 'react'
// import React, { useLayoutEffect, useState } from 'react'
import EventSignUpInline from "../Home/EventSignUp/EventSignUpInline";
import EventExcerpt from './EventExcerpt'

import {listEvents} from "../../utils/api/helpers/event";

const EventList = () => {

    const [eventList, setEventList] = useState(null)

    useEffect( () => {
        let subscribed = true
        if (subscribed) {
            listEvents()
                .then(result => {
                    console.log("events", result.data)
                    if (result.data) {
                        setEventList(result.data)
                    }
                })
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

    <>
      <div className="width_ctrl">
          <div className="events__grid pt-0 pt-md-5 mb-5" style={{marginTop: 75}}>




                  {eventList && Object.keys(eventList).length ?
                      <>
                          <h1 className="events__header text-center text-uppercase">EVENTS</h1>
                          <div className="events__grid--inner d-flex flex-column flex-md-row flex-md-wrap justify-content-center">
                              {
                                  eventList.map((o, i) => {
                                      return <EventExcerpt key={i} event_object={o}/>
                                  })
                              }
                          </div>
                      </>
                      :
                      <>
                          <h1 className="events__header text-center text-uppercase">STAY INFORMED</h1>
                          <EventSignUpInline />
                      </>
                  }


          </div>
      </div>
        <div className="main__background events__background" />
    </>

    )
}

export default EventList
