import React, { useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'

const TitleDetailNav = ({navSticky, activeSection, setActiveSection, hasMediaRead, hasPress, hasEvents, hasVideos, isLoggedIn, hasAwards, isMobile,hasPresentations}) => {

    const match = useParams()
    const location = useLocation()

    const selectedType = location.pathname.includes('/pga') ? "pga" : location.pathname.includes('/ves') ? "ves" : "";

    // useEffect( () => {
    //   let subscribed = true;
    //   if (subscribed) {
    //       console.log(activeSection)
    //   }
    //   return ()=>{subscribed=false}
    // }, [activeSection])

    const updateUrl = (data, title, url) => {
        window.history.pushState(data, title, url)
    }

    const scrollTo = (to) => {
        // let element = document.getElementById("scrollContainer");
        window.scroll({
            behavior: 'smooth',
            left: 0,
            top: isMobile ? (to.offsetTop -30) : (to.offsetTop + (window.innerHeight*.65))
        });
        // console.log(to.offsetTop)
        // to.scrollIntoView()
    }

    useEffect(() => {
        if (activeSection) {
            // setActiveSection('videos');
            updateUrl(activeSection, activeSection, `/${selectedType ? selectedType + "/" : ""}${match.categorySlug}/${match.titleSlug}/${activeSection}`
              );;
            setTimeout(() => {
                let el = document.getElementById("container_"+activeSection);
                if (el) {
                    // console.log(el, activeSection)
                    scrollTo(el);
                }
            }, 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeSection]);

  return (

      <div id="titleDetailNav" className={"titledetail__nav-container "+(navSticky?'active':'')}>
          <div>
              <button onClick={()=> {
                  setActiveSection('trailer');
                  updateUrl('trailer', 'Trailer', `/${selectedType ? selectedType + "/" : ""}${match.categorySlug}/${match.titleSlug}`);
                  let el = document.getElementById("container_trailer");
                  scrollTo(el);
              }} className={activeSection==='trailer'?'active':''}>Trailer</button>
          </div>
          {
              hasEvents ?
                  <div className={''}>
                      <button onClick={() => {
                          setActiveSection('events');
                          updateUrl('events', 'Events', `/${selectedType ? selectedType + "/" : ""}${match.categorySlug}/${match.titleSlug}/events`);
                          let el = document.getElementById("container_events");
                          scrollTo(el);
                      }} className={activeSection === 'events' ? 'active' : ''}>Events
                      </button>
                  </div>
                  : null
          }

          {
              hasPress ?
                  <div className={''}>
                      <button onClick={() => {
                          setActiveSection('press');
                          updateUrl('press', 'Press', `/${selectedType ? selectedType + "/" : ""}${match.categorySlug}/${match.titleSlug}/press`);
                          let el = document.getElementById("container_press");
                          scrollTo(el);
                      }} className={activeSection === 'press' ? 'active' : ''}>Press
                      </button>
                  </div>
                  : null
          }
          {
              hasPresentations ?
                  <div className={''}>
                      <button onClick={() => {
                          setActiveSection('design-presentations');
                          updateUrl('design-presentations', 'Design Presentations', `/${selectedType ? selectedType + "/" : ""}${match.categorySlug}/${match.titleSlug}/design-presentations`);
                          let el = document.getElementById("container_design-presentations");
                          scrollTo(el);
                      }} className={activeSection === 'design-presentations' ? 'active' : ''}>Design
                      </button>
                  </div>
                  : null
          }
          {
              hasVideos ?
                  <div>
                      <button onClick={() => {
                          setActiveSection('videos');
                          updateUrl('videos', 'Videos', `/${selectedType ? selectedType + "/" : ""}${match.categorySlug}/${match.titleSlug}/videos`);
                          let el = document.getElementById("container_videos");
                          scrollTo(el);
                      }} className={activeSection === 'videos' ? 'active' : ''}>Videos
                      </button>
                  </div>
              : null
          }
          {
              hasMediaRead ?
                  <div className={''}>
                      <button onClick={() => {
                          setActiveSection('scripts');
                          updateUrl('scripts', 'Scripts', `/${selectedType ? selectedType + "/" : ""}${match.categorySlug}/${match.titleSlug}/scripts`);
                          let el = document.getElementById("container_scripts");
                          scrollTo(el);
                      }} className={activeSection === 'scripts' ? 'active' : ''}>Scripts
                      </button>
                  </div>
              : null
          }
          {
              hasAwards ?
                  <div className={'d-block d-md-none'}>
                      <button onClick={() => {
                          setActiveSection('fyc');
                          updateUrl('fyc', 'FYC', `/${selectedType ? selectedType + "/" : ""}${match.categorySlug}/${match.titleSlug}/fyc`);
                          let el = document.getElementById("container_fyc");
                          scrollTo(el);
                      }} className={'w-100 ' + (activeSection === 'fyc' ? 'active' : '')}>FYC
                      </button>
                  </div>
                  : null
          }

      </div>
  )

}

export default TitleDetailNav
